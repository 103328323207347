export function mergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      if (obj2[p].constructor == Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

function parseRGB(rgbAsString) {
  const dec = [];
  let current = rgbAsString;
  dec.push(current.slice(current.indexOf("(") + 1, current.indexOf(",")));
  current = current.slice(current.indexOf(",") + 2);
  dec.push(current.slice(0, current.indexOf(",")));
  dec.push(current.slice(current.indexOf(",") + 2, current.length - 1));
  return dec.map((n) => (n.length < 2 ? [0, ...n].join("") : n));
}

function rgbToHex(r, g, b) {
  return `#${[r, g, b]
    .map((dec) => {
      const hex = String(Number(dec).toString(16));
      return hex.length < 2 ? [0, ...hex].join("") : hex;
    })
    .join("")}`;
}

export function generateColors(initialRGBColor, length) {
  const result = [];
  const parsedRGB = parseRGB(initialRGBColor);
  const initialHexColor = rgbToHex(parsedRGB[0], parsedRGB[1], parsedRGB[2]);
  result.push(initialHexColor);
  const b = "ff";
  for (let i = 1; i < length; i++) {
    let r = (parseInt(initialHexColor.slice(1, 3), 16) + 25 * i).toString(16);
    let g = (parseInt(initialHexColor.slice(3, 5), 16) + 20 * i).toString(16);
    result.push(`#${r}${g}${b}`);
  }
  return result;
}
